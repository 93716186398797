
import estampa1 from  "../NATAL/Natal 01.png"
import estampa2 from  "../NATAL/Natal 02.png"
import estampa3 from  "../NATAL/Natal 03.png"
import estampa4 from  "../NATAL/Natal 04.png"
import estampa5 from  "../NATAL/Natal 05.png"
import estampa6 from  "../NATAL/Natal 06.png"
import estampa7 from  "../NATAL/Natal 07.png"
import estampa8 from  "../NATAL/Natal 08.png"
import estampa9 from  "../NATAL/Natal 09.png"
import estampa10 from "../NATAL/Natal 010.png"
import estampa11 from "../NATAL/Natal 011.png"
import estampa12 from "../NATAL/Natal 012.png"
import estampa13 from "../NATAL/Natal 013.png"
 
 export  const imagensNATAL = [
      {
        "nome": "NATAL 01",
        "tipo": "png",
        "caminho": estampa1
      },
      {
        "nome": "NATAL 02",
        "tipo": "png",
        "caminho": estampa2
      },
      {
        "nome": "NATAL 03",
        "tipo": "png",
        "caminho": estampa3
      },
      {
        "nome": "NATAL 04",
        "tipo": "png",
        "caminho": estampa4
      },
      {
        "nome": "NATAL 05",
        "tipo": "png",
        "caminho": estampa5
      },
      {
        "nome": "NATAL 06",
        "tipo": "png",
        "caminho": estampa6
      },
      {
        "nome": "NATAL 07",
        "tipo": "png",
        "caminho": estampa7
      },
      {
        "nome": "NATAL 08",
        "tipo": "png",
        "caminho": estampa8
      },
      {
        "nome": "NATAL 09",
        "tipo": "png",
        "caminho": estampa9
      },
      {
        "nome": "NATAL 10",
        "tipo": "png",
        "caminho": estampa10
      },
      {
        "nome": "NATAL 11",
        "tipo": "png",
        "caminho": estampa11
      },
      {
        "nome": "NATAL 12",
        "tipo": "png",
        "caminho": estampa12
      },
      {
        "nome": "NATAL 13",
        "tipo": "png",
        "caminho": estampa13
      }
    ]
  