import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { imagensDoceria } from "../Briefing/img/DOCERIA/estampasDoceria";
import { imagensDrink } from "../Briefing/img/DRINKS/drinksEstampas";
import { imagensPadaria } from "../Briefing/img/PADARIA/estampasPadaria";
import { imagensRestauranteDelivery } from "../Briefing/img/RESTAURANTE - DELIVERY - LANCHONETE/estampasRestauranteDelivery";
import { imagensMilkShake } from "../Briefing/img/MILK SHAKE/estampasMilkShake";
import { imagensClinica } from "../Briefing/img/CLÍNICA/estampasClinica";
import { imagensCafeteria } from "../Briefing/img/CAFETERIA/estampasCafeteria";
import { imagensEscritorio } from "../Briefing/img/ESCRITÓRIO/estampasEscritorio";
import { imagensAcai } from "../Briefing/img/AÇAÍ/estampasAcai";
import { imagensSorveteria } from "../Briefing/img/SORVETERIA/estampasSorveteria";
import { FileUploadOutlined } from "@mui/icons-material";
import Cup3D from "./Prototipo";

const PrototipoForm = () => {
  const [formData, setFormData] = useState({});
  const [segmento, setSegmento] = useState(null);
  const [selectedEstampa, setSelectedEstampa] = useState(null);
  const [step, setStep] = useState(1);
  const [uploadedImage, setUploadedImage] = useState(null); // Para armazenar a URL temporária da imagem
  const [estampaBase64, setEstampaBase64] = useState(null);
  const [logoBase64, setLogoBase64] = useState(null);

  const handleCheckboxChangeEstampa = (value) => {
    setSelectedEstampa(value);
  };

  const convertImageToBase64 = async (imageUrl) => {
    const response = await fetch(imageUrl); // Busca a imagem pela URL
    const blob = await response.blob(); // Converte a resposta em um blob (arquivo binário)

    return new Promise((resolve, reject) => {
      const reader = new FileReader(); // Usa o FileReader para ler o blob como DataURL (base64)
      reader.onloadend = () => {
        resolve(reader.result); // Retorna o resultado em base64
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(blob); // Converte o blob em base64
    });
  };

  useEffect(() => {
    if (selectedEstampa) {
      convertImageToBase64(selectedEstampa).then((base64Image) => {
        setEstampaBase64(base64Image); // Armazena a estampa em base64
      });
    }
  }, [selectedEstampa]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedImage(reader.result); // Armazena a URL temporária no estado
      };
      reader.readAsDataURL(file); // Lê o arquivo como DataURL
    }
  };

  useEffect(() => {
    if (uploadedImage) {
      setLogoBase64(uploadedImage); // Armazena a logo em base64
    }
  }, [uploadedImage]);

  const segmentos = {
    Doceria: {
      estampas: imagensDoceria,
    },
    Drinks: {
      estampas: imagensDrink,
    },
    Padaria: {
      estampas: imagensPadaria,
    },
    "Restaurante/Delivery": {
      estampas: imagensRestauranteDelivery,
    },
    "Milk Shake": {
      estampas: imagensMilkShake,
    },
    Clínica: {
      estampas: imagensClinica,
    },
    Cafeteria: {
      estampas: imagensCafeteria,
    },
    Escritório: {
      estampas: imagensEscritorio,
    },
    Açai: {
      estampas: imagensAcai,
    },
    Sorveteria: {
      estampas: imagensSorveteria,
    },
    Outros: {
      estampas: [],
    },
  };

  return (
    <div
    style={{
      background: "linear-gradient(to top left, white, #83a802)",
      minHeight: "100vh",
      maxWidth: "100vw",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      fontFamily: "Montserrat",
      padding: "10px",
    }}
  >
    <Box display="flex" flexDirection="column" alignContent="center" alignItems="center">
      {step === 1 && (
        <Box>
          <h1 style={{ textAlign: "center" }}>Selecione seu segmento:</h1>
          <Box>
            <FormControl fullWidth>
              <InputLabel id="segmento-label">Selecione seu segmento aqui</InputLabel>
              <Select
                label="Segmento"
                onChange={(e) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    Segmento: e.target.value,
                  }));
                  setSegmento(e.target.value);
                }}
                value={segmento}
              >
                {Object.keys(segmentos).map((key) => (
                  <MenuItem key={key} value={key}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      )}

      {step === 1 && segmento && segmento !== "Outros" && (
        <Box>
          <h1 style={{ textAlign: "center" }}>Selecione uma das estampas</h1>
          <Box
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(1, 1fr)", // 1 coluna em telas pequenas
              sm: "repeat(2, 1fr)", // 2 colunas em telas médias
              md: "repeat(3, 1fr)", // 3 colunas em telas grandes
            }}
            gap="10px"
            width="100%"
            justifyContent="center"
            alignItems="center"
            justifyItems="center"
          >
            {segmentos[segmento]?.estampas?.map((image, index) => (
              <Card key={index} sx={{ width: "100%" }}>
                <CardActionArea
                  onClick={() => handleCheckboxChangeEstampa(`https://briefing.papellodev.site${image.caminho}`)}
                >
                  <CardMedia
                    component="img"
                    height="250"
                    image={image.caminho}
                    alt="Estampa"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      {image.nome}
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedEstampa === `https://briefing.papellodev.site${image.caminho}`}
                          onChange={() => handleCheckboxChangeEstampa(`https://briefing.papellodev.site${image.caminho}`)}
                          onClick={(e) => e.stopPropagation()}
                        />
                      }
                      label="Selecionar"
                    />
                  </CardContent>
                </CardActionArea>
              </Card>
            ))}
          </Box>
        </Box>
      )}

      {step === 1 && (
        <Box mt={1}>
          <Button color="success" variant="contained"  onClick={() => setStep(2)}>Próximo</Button>
        </Box>
      )}

      {step === 2 && (
        <Box textAlign="center">
          <Typography variant="h6">Envie sua logo em formato PNG</Typography>
          <input
            style={{ display: "none" }}
            type="file"
            accept="image/png"
            onChange={handleFileChange}
            id="contained-button-file"
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="outlined"
              component="span"
              color="success"
              startIcon={<FileUploadOutlined />}
            >
              Envie sua logo aqui
            </Button>
          </label>
          {uploadedImage && (
            <Box mt={2} display="flex" flexDirection="column">
              <Box mt={2} display="flex" flexDirection="column">
                <Typography variant="h6">Pré-visualização da Logo:</Typography>
                <img
                  src={uploadedImage}
                  alt="Logo"
                  style={{ width: "100%", maxWidth: "300px" }}
                />
              </Box>
              <Button color="success" variant="contained" onClick={() => setStep(3)}>Próximo</Button>
              </Box>
          )}

        </Box>
        
      )}

      {step === 3 && logoBase64 && estampaBase64 && (
        <Box textAlign="center">
            <Cup3D logo={logoBase64} estampa={estampaBase64} />
            <Button variant="contained"  onClick={() => setStep(1)}>Voltar</Button>
        </Box>
      )}
    </Box>
    </div>
  );
};

export default PrototipoForm;
