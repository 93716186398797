import { jwtDecode } from 'jwt-decode';
import config from '../../../config/config';



const getUrlParams = () => {
    const params = new URLSearchParams(window.location.search);
    return {
      numero_pedido: params.get('numero_pedido'),
      cliente: params.get('cliente'),
      produto: params.get('produto'),
    };
  };

export async function fetchNewToken() {
    const { numero_pedido, cliente, produto } = getUrlParams();
    const url = new URL(`${config.apiUrl}auth`);
    url.searchParams.append('numero_pedido', numero_pedido);
    url.searchParams.append('cliente', cliente);
    url.searchParams.append('produto', produto);

    try {
        const response = await fetch(url,
            {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json'
                }
            }
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.token;
      } catch (error) {
        console.error('Failed to fetch new token:', error);
        return null;
      }
    
  }
  
  // Função para verificar e atualizar o token
export async function ensureToken(validateBriefing) {
    const token = new URLSearchParams(window.location.search).get('token');
    sessionStorage.setItem('token', token)
    // const storedToken = sessionStorage.getItem('token');
    // if (!storedToken) {
    //   // Se não houver token, gere um novo
    //   const newToken = await fetchNewToken();
    //   sessionStorage.setItem('token', newToken);
    // } else {
    //   // Verifique se o token está expirado
    //   const decodedToken = jwtDecode(storedToken);
    //   const currentTime = Math.floor(Date.now() / 1000); // Tempo atual em segundos
      
    //   if (decodedToken.exp < currentTime) {
    //     // Token expirado, gere um novo
    //     const newToken = await fetchNewToken();
    //     sessionStorage.setItem('token', newToken);
    //   }
    // }
    validateBriefing();
  }