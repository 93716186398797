import React, { useState, useEffect } from "react";
import { jwtDecode } from 'jwt-decode';
import { Box } from "@mui/material";

const TokenTimer = ({ token }) => {
  const [timeLeft, setTimeLeft] = useState(null);
  const [expired, setExpired] = useState(false);

  useEffect(() => {
    if (token) {
      // Decodifica o token JWT
      const decodedToken = jwtDecode(token);

      // Calcula o tempo de expiração (em segundos)
      const expirationTime = decodedToken.exp * 1000; // exp está em segundos, por isso multiplicamos por 1000
      const currentTime = Date.now();
      const timeRemaining = expirationTime - currentTime;

      if (timeRemaining > 0) {
        setTimeLeft(timeRemaining);

        // Atualiza o tempo restante a cada segundo
        const intervalId = setInterval(() => {
          const newTimeRemaining = expirationTime - Date.now();
          if (newTimeRemaining > 0) {
            setTimeLeft(newTimeRemaining);
          } else {
            clearInterval(intervalId);
            setExpired(true); // Marca como expirado
            setTimeLeft(0);
          }
        }, 1000);

        // Limpa o intervalo quando o componente desmontar
        return () => clearInterval(intervalId);
      } else {
        setExpired(true);
        setTimeLeft(0);
      }
    }
  }, [token]);

  // Função para formatar o tempo restante em minutos e segundos
  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <Box textAlign="center">
      {expired ? (
        <p>O Link expirou!</p>
      ) : (
        <p style={{fontWeight:"bold"}}>Tempo restante: {timeLeft !== null ? formatTime(timeLeft) : "Carregando..."}</p>
      )}
    </Box>
  );
};

export default TokenTimer;
