import { Box } from "@mui/material";
import React from "react";
import TokenTimer from "./TokenTimer";

const Header = () => {
    return (
        <Box display="flex" alignItems="flex-start" top="0" left="0" marginBottom={3} > 
            <header>
                <img src="https://acdn.mitiendanube.com/stores/002/907/105/themes/common/logo-1336738559-1706047471-a90c2b04f7208c4f190adf866d8df0b51706047472-320-0.webp" alt="Logo Papello" />
                <TokenTimer token={sessionStorage.getItem('token')} />
            </header>
        </Box>

    );
}

export default Header;