import config from "../../../config/config";

export const saveBriefing = async (briefing, pedido, cliente, produto, uploading, setUploading, setBriefingEnviado, infoOrder) => {
    setUploading({ uploading: true, status: "Enviando Briefing..." });
    console.log('Salvando briefing:', briefing, pedido, cliente, produto);
    const estampa = (briefing.Estampa && briefing.Estampa.includes("https")) ? encodeURI(briefing.Estampa) : briefing.Estampa;
    let infoContato = "";
    if (briefing.Facebook || briefing.Instagram || briefing.WhatsApp || briefing.Telefone) {
        infoContato = `Facebook: ${briefing.Facebook ? briefing.Facebook : ""}\nInstagram: ${briefing.Instagram ? briefing.Instagram : ""}\nWhatsApp: ${briefing.WhatsApp ? briefing.WhatsApp : ""}\nTelefone: ${briefing.Telefone ? briefing.Telefone : ""}`;
    }
    let body = {
        store_id: 2907105,
        order_id: Number(pedido),
        art_sku: Number(produto),
        cid: Number(cliente),
        nicho: briefing.Segmento,
        termos: "on"
    };
    
    // Condicionalmente adicionando as propriedades que existem em briefing
    if (briefing["Cor da Estampa"] || briefing.Estampa) {
        body.cor_copo = briefing["Cor da Estampa"] ? briefing["Cor da Estampa"] : estampa;
    }
    
    if (briefing["Referência para estampa"]) {
        body.descricao = briefing["Referência para estampa"];
    }
    
    if (briefing.Logo) {
        body.logo = briefing.Logo;
    }
    
    if (briefing.Estampa) {
        body.referencia = estampa;
    }
    
    if (briefing["Mais Referências"]) {
        body.mais_referencias = briefing["Mais Referências"];
    }
    
    if (briefing["Tabela Nutricional"]) {
        body.tabela_nutricional = briefing["Tabela Nutricional"];
    }
    
    if (briefing["Modelo Nutrientes"]) {
        body.modelo_nutrientes = Array.isArray(briefing["Modelo Nutrientes"]) 
            ? briefing["Modelo Nutrientes"].join(', ') 
            : briefing["Modelo Nutrientes"];
    }
    
    if (briefing["Manual de Marca"]) {
        body.manual_marca = briefing["Manual de Marca"];
    }
    
    if (briefing["Posição da Logo"]) {
        body.posicao_logo = briefing["Posição da Logo"];
    }
    
    if (briefing["Observações"]) {
        body.observacoes = briefing["Observações"];
    }
    
    if (briefing.Frase) {
        body.frase_logo = briefing.Frase;
    }
    if (infoContato) {
        body.info_contato = infoContato;
    }
    if (briefing["Arte Pronta"]) {
        body = {
            "store_id": 2907105,
            "order_id": +pedido,
            "art_sku": +produto,
            "cid": +cliente,
            "custom_orders_id": `${infoOrder.custom_orders_id}`,
            "ns_customer_id": +cliente,
            "art_final_url": briefing["Arte Pronta"],
        }
    }
    if (briefing["Referência"]) {
        body = {
            "store_id": 2907105,
            "order_id": +pedido,
            "art_sku": +produto,
            "cid": +cliente,
            "referencia": briefing["Referência"],
        }
    }

    try {
        const response = await fetch(`${config.apiUrl}saveBriefing`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(body)
        });
    
        const result = await response.json();
        console.log('Briefing salvo com sucesso:', result);
        setUploading({ uploading: false, status: "Briefing enviado com sucesso!" });
        setBriefingEnviado(true);
        return result.data;
    } catch (error) {
        console.error('Erro ao salvar briefing:', error.message);
        setUploading({ uploading: false, status: "Erro ao enviar briefing!" });
    }
}

export const getBriefings = async () => {
    const numero_pedido = new URLSearchParams(window.location.search).get('numero_pedido');
    const produto = new URLSearchParams(window.location.search).get('produto');
    const cliente = new URLSearchParams(window.location.search).get('cliente');
    console.log('Buscando briefings para:', numero_pedido, produto, cliente);
    try {
        const response = await fetch(`${config.apiUrl}briefings/?numero_pedido=${numero_pedido}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                'Origin': window.location.origin // Adiciona o header Origin
            }
        });
        const data = await response.json();
        const briefingPendente = data.result.find(briefing => +briefing.art_sku === +produto && +briefing.ns_customer_id === +cliente && (briefing.custom_order_item_status_id === "64e66e8d4b12c4926ae77d17" || briefing.custom_order_item_status_id === "64f0b35ecebccd05757254fd"));
        if (briefingPendente) {
            console.log('Briefing pendente encontrado:', briefingPendente);
            return briefingPendente;
        } else {
            console.log('Nenhum briefing pendente encontrado');
            return {status: 'error', message: "Briefing já preenchido para esse produto!"};
        }
        
    } catch (error) {
        console.error('Erro ao buscar briefings:', error.message);
        return {status: 'error', message: "Link expirado. Retorne a sua área do cliente e tente novamente!"};
    }
}

export const getBriefingsForReplicant = async () => {
    const cliente = new URLSearchParams(window.location.search).get('cliente');
    const produto = new URLSearchParams(window.location.search).get('produto');
    const pedido = new URLSearchParams(window.location.search).get('numero_pedido');
    try {
        const response = await fetch(`${config.apiUrl}replicable-art-choices/?cliente=${cliente}&produto=${produto}&pedido=${pedido}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                'Origin': window.location.origin // Adiciona o header Origin
        }
        });
        const data = await response.json();
        return data;
    }
    catch (error) {
        console.error('Erro ao buscar briefings:', error.message);
    }
}