import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Briefing from './pages/Briefing/Briefing';
import BriefingTampa from './pages/Briefing/BriefingTampa';
import BriefingKraft from './pages/Briefing/BriefingKraft';
import Cup3D from './pages/Prototipo/Prototipo';
import PrototipoForm from './pages/Prototipo/PrototipoForm';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Briefing />} />
          <Route path="/tampa" element={<BriefingTampa />} />
          <Route path='/kraft' element={<BriefingKraft />} />
          <Route path='/prototipo' element={<PrototipoForm />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
