import { Box, Button, Checkbox, FormControlLabel, Modal, Typography } from '@mui/material';
import React from 'react';
import GenericUploadQuestion from './GenericUploadQuestion';
import ColorPicker from './ColorPicker';
import imageAltoEmGorduras from "../img/alto-em-gordura.png";
import imageAltoEmAcucar from "../img/alto-em-acucar.png";
import imageAltoEmSodio from "../img/alto -em-sodio.png";

const style = {
    position: 'absolute',
    maxHeight: '90vh',
    overflowY: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
      xs: "80%",
      sm: "70%",
      md: "60%",
      lg: "40%",
      xl: "40%"
    },
    background: 'linear-gradient(to top left, white, #83a802)',
    border: '1px solid #000',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
  };

const EditModal = ({type, nameInput, question, setFormData, item, uploading, fileQuestionButton, handleFileChange, setUploading, formState, handleCheckboxChangeNutrientsModel, setStep}) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  
    return (
      <div>
        <Button onClick={handleOpen} color="warning" variant="contained" >Editar</Button>
        <Modal
          open={open}
          onClose={() => {
            handleClose()
            setUploading({uploading: false, status: null })
        }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {type === 'input' && (
                <GenericUploadQuestion
                    question={question}
                    nameInput={nameInput}
                    setFormData={setFormData}
                    item={item}
                    uploading={uploading}
                    fileQuestionButton={fileQuestionButton}
                    showButtons={false}
                    handleFileChange={handleFileChange}
                />
            )}
             {type === 'colorPicker' && (
                <ColorPicker
                    setFormData={setFormData}
                    item={item}
                    formField={nameInput}
                    setValue={(formField, value) => setFormData(prevState => ({ ...prevState, [formField]: value }))}
                />
             )}
            {type === 'nutrientsModel' && (
                <Box display="flex" flexDirection="column" alignItems="center">
                <h1 style={{ textAlign: "center" }}>
                  Selecione as opções desejadas
                </h1>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="Alto em açúcar adicionado"
                      name="Alto em açúcar adicionado"
                      checked={formState.nutrientsModel.includes(
                        "Alto em açúcar adicionado"
                      )}
                      onChange={() =>
                        handleCheckboxChangeNutrientsModel(
                          "Alto em açúcar adicionado"
                        )
                      }
                    />
                  }
                  label={
                    <Box display="flex" alignItems="center">
                      <img
                        src={imageAltoEmAcucar}
                        alt="Alto em gordura"
                        style={{ marginLeft: 8, width: 150, height: 35 }}
                      />
                    </Box>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="Alto em gordura saturada"
                      name="Alto em gordura saturada"
                      checked={formState.nutrientsModel.includes(
                        "Alto em gordura saturada"
                      )}
                      onChange={() =>
                        handleCheckboxChangeNutrientsModel(
                          "Alto em gordura saturada"
                        )
                      }
                    />
                  }
                  label={
                    <Box display="flex" alignItems="center">
                      <img
                        src={imageAltoEmGorduras}
                        alt="Alto em gordura"
                        style={{ marginLeft: 8, width: 150, height: 35 }}
                      />
                    </Box>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="Alto em sódio"
                      name="Alto em sódio"
                      checked={formState.nutrientsModel.includes("Alto em sódio")}
                      onChange={() =>
                        handleCheckboxChangeNutrientsModel("Alto em sódio")
                      }
                    />
                  }
                  label={
                    <Box display="flex" alignItems="center">
                      <img
                        src={imageAltoEmSodio}
                        alt="Alto em gordura"
                        style={{ marginLeft: 8, width: 150, height: 35 }}
                      />
                    </Box>
                  }
                />       
            </Box>
            )}
            {nameInput === 'Estampa' && formState.selectedEstampa !== null && (
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Button
                     color="info"
                     variant="contained"
                     onClick={() => {
                        setStep(6)
                     }}
                    >
                        Selecionar estampas prontas
                    </Button>
                </Box>
            )}
            <Box display="flex" flexDirection="column" alignItems="center" justifyItems="center" mt={1} >
            <Button
              onClick={() => {
                handleClose()
                setUploading({uploading: false, status: null })
            }}
              color="error"
              variant="contained"
              sx={{
                alignSelf: "center",
                width: {
                  xs: "80%",
                  sm: "70%",
                  md: "60%",
                  lg: "40%",
                  xl: "40%"
                },
                mt: 2
              }}
            >
              Fechar
            </Button>
          </Box>
        </Box>

  
        </Modal>
      </div>
    );
}

export default EditModal;