import { FileDownloadOutlined, FileUploadOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Input,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getBriefingsForReplicant } from "../utils/saveBriefing";
import { set } from "react-hook-form";

const ReplicacaoArte = ({
  handleFileChange,
  fileName,
  uploading,
  onPreviousStep,
  onNextStep,
  question,
  gabarito,
  fileQuestionButton,
  briefingFinalizado,
  nameInput,
  steps,
  hasManual,
  setHasManual,
  finalSteps,
  previousSteps,
  referencia,
  setFormData,
  setHasArt,
  replicableArts,
}) => {
  const [artesParaReplicar, setArtesParaReplicar] = useState([]);
  const [selectedArt, setSelectedArt] = useState(null);
  const [loading, setLoading] = useState(false);
  console.log("referencia", referencia);
  const isReferenceMissing = referencia ? !referencia : true;
  console.log("isReferenceMissing", isReferenceMissing);

  const getArtsForReplicant = () => {
    setArtesParaReplicar(replicableArts)
  };

  useEffect(() => {
    getArtsForReplicant();
  }, []);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {artesParaReplicar?.length > 0 ? (
        <>
          <h1 style={{ textAlign: "center" }}>{question}</h1>
          {artesParaReplicar.map((arte, index) => (
            <Box
              key={index}
              display="flex"
              flexDirection="column"
              alignItems="center"
              mb={1}
              width="100%"
            >
              {arte.briefings &&
                arte.briefings.map((briefing, index) => (
                  <Box
                    key={index}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    mb={1}
                    width="100%"
                  >
                    <Card sx={{ width: "100%", maxWidth: 600 }}>
                      <CardActionArea
                        onClick={(e) => {
                          setSelectedArt(
                            briefing.art_final_url ||
                              briefing.briefing_obj.art_final_url
                          );
                          setFormData((prevState) => ({
                            ...prevState,
                            ["Arte Pronta"]:(
                              briefing.art_final_url ||
                                briefing.briefing_obj.art_final_url
                            ),
                          }));
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            height: "0",
                            paddingBottom: "68%", // Mantém a proporção quadrada
                            position: "relative",
                          }}
                        >
                          <iframe
                            title={briefing.product_name}
                            src={(
                              briefing.briefing_obj?.art_final_url ||
                              briefing.art_final_url
                            ).replace("dl=0", "raw=1")}
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              border: "none",
                            }}
                          ></iframe>
                        </Box>
                        <CardContent>
                          <Typography gutterBottom variant="h6">
                            {briefing.product_name}
                          </Typography>
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={
                                  briefing?.art_final_url ||
                                  briefing?.briefing_obj.art_final_url
                                }
                                onClick={(e) => {
                                  console.log("e.target.value", e.target.value);
                                  setSelectedArt(
                                    briefing.art_final_url ||
                                      briefing.briefing_obj.art_final_url
                                  );
                                  setFormData((prevState) => ({
                                    ...prevState,
                                    ["Arte Pronta"]: e.target.value,
                                  }));
                                }}
                                checked={
                                  selectedArt ===
                                  (briefing?.art_final_url ||
                                    briefing?.briefing_obj.art_final_url)
                                }
                              />
                            }
                            label="Selecionar"
                          />
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Box>
                ))}
            </Box>
          ))}

          <Box display="flex" justifyContent="center" margin="10px">
            <Button
              variant="contained"
              type="button"
              onClick={() => onPreviousStep(previousSteps ? previousSteps : 1)}
              color="secondary"
            >
              Anterior
            </Button>
            <Box width="20px" />
            <Button
              variant="contained"
              type="submit"
              color="success"
              onClick={() => {
                let realSteps = steps;
                realSteps = 14;
                onNextStep(realSteps);
              }}
              disabled={!selectedArt}
            >
              Próximo
            </Button>

            {briefingFinalizado && !gabarito && (
              <Box display="flex">
                <Box width="20px" />
                <Button
                  variant="contained"
                  disabled={!selectedArt}
                  color="info"
                  onClick={() => onNextStep(finalSteps)}
                >
                  Finalizar
                </Button>
              </Box>
            )}
          </Box>
        </>
      ) : (
        <Box>
          {!loading && (
            <h1>
              Parece que você não tem artes disponíveis para replicar, favor
              preencher o briefing normal.
            </h1>
          )}
        </Box>
      )}
      {loading && (
        <Box mt={5}>
          <Typography>
            Buscando artes disponíveis ...
            <CircularProgress size={20}></CircularProgress>
          </Typography>
        </Box>
      )}
      {artesParaReplicar == null && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          marginBottom={5}
        >
          <Box height="20px" />
          <Box display="flex" justifyContent="center" margin="10px">
            <Button
              variant="contained"
              type="button"
              onClick={() => onPreviousStep(previousSteps ? previousSteps : 1)}
              color="secondary"
            >
              Anterior
            </Button>
            <Box width="20px" />
            <Button
              variant="contained"
              type="submit"
              color="success"
              onClick={() => {
                setHasArt(false);
                onNextStep(0);
              }}
            >
              Próximo
            </Button>

            {briefingFinalizado && !gabarito && (
              <Box display="flex">
                <Box width="20px" />
                <Button
                  variant="contained"
                  disabled={!fileName || referencia === ""}
                  color="info"
                  onClick={() => onNextStep(finalSteps)}
                >
                  Finalizar
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ReplicacaoArte;
