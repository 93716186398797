import { Box, Button, TextField } from "@mui/material";
import React, { useEffect } from "react";
import GenericUploadQuestion from "./GenericUploadQuestion";

const Step3 = ({
  hasLogo,
  setHasArt,
  onNextStep,
  handleFileChange,
  uploading,
  fileName,
  onPreviousStep,
  briefingFinalizado,
  register,
  setFormData,
  logo,
  nextStep,
  finalSteps,
  setStep,
  totalSteps,
}) => {
  useEffect(() => {
    console.log(logo);
    if (logo && logo.includes("http")) {
      console.log("entrou");
      setFormData((prevState) => ({ ...prevState, Logo: "" }));
    }
  }, []);

  if (hasLogo === false)
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignContent="center"
        alignItems="center"
      >
        <h1 style={{ textAlign: "center" }}>Qual o nome da sua marca?</h1>
        <p style={{textAlign: "center"}}>*Se não possuir marca, digite "<span style={{fontWeight: "bold"}}>não possuo marca</span>".</p>
        <TextField
          {...register("Logo", { required: true })}
          onChange={(e) => {
            setFormData((prevState) => ({
              ...prevState,
              Logo: e.target.value,
            }));
          }}
          value={logo}
          label="Qual o nome da sua marca?"
        />
        <p style={{ color: "#871111", textAlign: "center" }}>
          <span>*</span>Atenção: Lembrando que a Papello não faz criações de
          Logomarca, aplicaremos o nome da sua marca na embalagem da melhor
          maneira possível.
        </p>
        <Box display="flex" justifyContent="center" margin="10px"></Box>
        <Button
          color="success"
          variant="contained"
          type="button"
          onClick={() => onNextStep(2)}
          disabled={!logo}
        >
          Próximo
        </Button>
        <Box display="flex" justifyContent="center" margin="10px" />
        <Button
          color="secondary"
          variant="contained"
          type="button"
          onClick={() => onPreviousStep()}
          disabled={hasLogo === null}
        >
          Anterior
        </Button>
        <Box display="flex" justifyContent="center" margin="10px" />
        {briefingFinalizado && (
          <Button
            color="info"
            variant="contained"
            type="button"
            onClick={() => setStep(totalSteps)}
          >
            Finalizar
          </Button>
        )}
      </Box>
    );
  return (
    <GenericUploadQuestion
      handleFileChange={handleFileChange}
      fileName={fileName}
      uploading={uploading}
      onPreviousStep={onPreviousStep}
      onNextStep={onNextStep}
      question="Envie sua logo aqui"
      gabarito={false}
      fileQuestionButton="Envie sua logo aqui"
      briefingFinalizado={briefingFinalizado}
      nameInput="Logo"
      finalSteps={11}
    />
  );
};

export default Step3;
