import { FileDownloadOutlined, FileUploadOutlined } from '@mui/icons-material';
import { Box, Button,CircularProgress, Input, Typography } from '@mui/material';
import React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';


const GenericUploadQuestion = ({ 
    handleFileChange, fileName, uploading, onPreviousStep, onNextStep, question, gabarito, fileQuestionButton, briefingFinalizado, nameInput, steps, hasManual, setHasManual, finalSteps, previousSteps, referencia, showButtons = true
}) => {
    return (
        <Box display="flex" flexDirection="column" alignItems="center">
        <h1 style={{ textAlign: "center" }}>{question}</h1>
        <Box display="flex" flexDirection="column" alignItems="center">
            {gabarito && (
                <a href='https://drive.google.com/drive/folders/16ffcvHYBMIVc1p3mMETDq7v_fEl0mVaQ' target='_blank' rel="noreferrer">
                <Button variant="outlined" color="success">
                    Baixar Gabarito da Papello
                    <FileDownloadOutlined />
                </Button>
                </a>

                )}
            <Box height="20px" />
            <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
                <input
                    accept=".pdf,.png,.jpg,.jpeg,.ai,.psd,.cdr"
                    style={{ display: 'none' }}
                    id="contained-button-file"
                    multiple
                    name={nameInput}
                    type="file"
                    onChange={handleFileChange}
                />
                <label htmlFor="contained-button-file">
                    <Button
                        variant="outlined"
                        component="span"
                        color="success"
                        startIcon={<FileUploadOutlined />}
                    >
                        {fileQuestionButton}
                    </Button>
                </label>

                {fileName && (
                    <Typography variant="body1" mt={2}>
                        Arquivo selecionado: {fileName}
                    </Typography>
                )}

                {uploading.status && (
                    <Typography variant="body1" mt={2} color={uploading.status === "Arquivo enviado com sucesso!" && "green"} >
                        {uploading.status} {uploading.uploading && <CircularProgress size={20} />} {uploading.status === "Arquivo enviado com sucesso!" && <CheckCircleOutlineIcon />}
                    </Typography>
                )}
                <p style={{ color: "#871111", textAlign: "center" }}><span>*</span>Aceitamos arquivos nas extensões Illustrator, Photoshop, Corel Draw, PDF ou PNG de alta resolução (mínimo 300dpi)</p>
            </Box>
        </Box>
        {showButtons && (
        <Box display="flex" justifyContent="center" margin="10px">
            <Button variant="contained" type="button" onClick={() => onPreviousStep(previousSteps? previousSteps : 1)} color="secondary">Anterior</Button>
            <Box width="20px" />
            <Button variant="contained" type="submit" color="success" onClick={() => {
                let realSteps = steps
                if (hasManual){
                    setHasManual(false);
                    realSteps = 0
                }
                if (gabarito){
                    realSteps = 13;
                }
                onNextStep(realSteps)}} disabled={!fileName || referencia === ""}>Próximo</Button>
            
            {(briefingFinalizado && !gabarito) && (
            <Box display="flex">
                <Box width="20px" />
                <Button variant="contained" disabled={!fileName || referencia === ""} color="info" onClick={() => onNextStep(finalSteps)}>Finalizar</Button>
            </Box>
          )}
        </Box>
        )}
    </Box>
    );
}

export default GenericUploadQuestion;