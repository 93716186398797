import React, { useEffect, useState } from "react";
import {
  Checkbox,
  TextField,
  Button,
  Box,
  Typography,
  FormControlLabel,
  CircularProgress,
  Link,
  List,
  ListItem,
  Input,
} from "@mui/material";
import { useForm } from "react-hook-form";
import ColorPicker from "./components/ColorPicker";
import LinearWithValueLabel from "./components/LinearProgressWithLabel";
import imageAltoEmGorduras from "./img/alto-em-gordura.png";
import imageAltoEmAcucar from "./img/alto-em-acucar.png";
import imageAltoEmSodio from "./img/alto -em-sodio.png";
import { uploadFileToDropbox } from "./utils/uploadToDropbox";
import { getBriefings, getBriefingsForReplicant, saveBriefing } from "./utils/saveBriefing";
import GenericUploadQuestion from "./components/GenericUploadQuestion";
import GenericStepQuestion from "./components/GenericStepQuestion";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import BriefingEnviado from "./components/BriefingEnviado";
import { ensureToken } from "./utils/authFunctions";
import BasicModal from "./components/ModalDeConfirmacao";
import Termos from "./components/Termos";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { FileUploadOutlined } from "@mui/icons-material";
import EditModal from "./components/EditModal";

function BriefingTampa() {
  const { register, handleSubmit, setValue } = useForm();
  const [hasBriefing, setHasBriefing] = useState(false);
  const [briefingEnviado, setBriefingEnviado] = useState(false);
  const [infoOrder, setInfoOrder] = useState(null);
  const [fraseErro, setFraseErro] = useState();
  const [step, setStep] = useState(() => {
    const storedStep = localStorage.getItem("step");
    return storedStep ? JSON.parse(storedStep) : 1;
  });
  const [termos, setTermos] = useState(false);

  const [formState, setFormState] = useState(() => {
    const storedState = localStorage.getItem("formState");
    return storedState
      ? JSON.parse(storedState)
      : {
          hasArt: null,
          hasLogo: null,
          hasManual: null,
          hasEstampa: null,
          selectedEstampa: null,
          hasContactInfo: null,
          contactInfo: {},
          logoPosition: null,
          hasNutritionalTable: null,
          hasHighNutrientsModel: null,
          nutrientsModel: [],
          briefingFinalizado: false,
          estampaEnviada: false,
          uploading: {
            uploading: false,
            status: "",
          },
          segmento: null,
          fileName: "",
        };
  });
  const [uploading, setUploading] = useState({
    uploading: false,
    status: "",
  });
  const [formData, setFormData] = useState(() => {
    const storedData = localStorage.getItem("formData");
    return storedData ? JSON.parse(storedData) : {};
  });
  const [confirmacaoReplicacao, setConfirmacaoReplicacao] = useState(formData["Arte Pronta"] ? false : null);
  const [briefingForReplicant, setBriefingForReplicant] = useState(null);
  const totalSteps = 16;
  console.log("formdata", formData["Arte Pronta"]);
  console.log("confirmacaoReplicacao", confirmacaoReplicacao);

  const getArtsForReplicant = async () => {
    // setLoading(true);
    const arts = await getBriefingsForReplicant();
    if (arts && arts.length > 0) {
      setBriefingForReplicant(arts);
    } else {
      setBriefingForReplicant([]);
      setStep(2);
    }
    //setLoading(false);
  };

  const updateFormState = (key, value) => {
    setFormState((prevState) => {
      const newState = { ...prevState, [key]: value };
      return newState;
    });
  };

  const validateBriefing = async () => {
    const productBriefing = await getBriefings();
    console.log(productBriefing);
    if (productBriefing.status === "error") {
      setFraseErro(productBriefing.message);
    }
    if (productBriefing !== null && !productBriefing.status) {
      setHasBriefing(true);
      setInfoOrder(productBriefing);
    } else {
      setHasBriefing(false);
    }
  };

  useEffect(() => {
    if (formData["Arte Pronta"] !== undefined) {
      setConfirmacaoReplicacao(formData["Arte Pronta"] ? false : null);
    }
  }, [formData["Arte Pronta"]]);

  useEffect(() => {
    ensureToken(validateBriefing);

    const produto = new URLSearchParams(window.location.search).get("produto");
    const produtoLS = JSON.parse(localStorage.getItem("produto"));
    console.log(produto, produtoLS);
    if (produto !== produtoLS) {
      console.log("Produto diferente");
      localStorage.setItem("produto", JSON.stringify(produto));
      setStep(1);
      setFormState({
        hasArt: null,
        hasLogo: null,
        hasManual: null,
        hasEstampa: null,
        selectedEstampa: null,
        hasContactInfo: null,
        contactInfo: {},
        logoPosition: null,
        hasNutritionalTable: null,
        hasHighNutrientsModel: null,
        nutrientsModel: [],
        briefingFinalizado: false,
        estampaEnviada: false,
        uploading: {
          uploading: false,
          status: "",
        },
        segmento: null,
        fileName: "",
      });
      setFormData({});
    }
    if(briefingForReplicant === null) {
      getArtsForReplicant();
    }
    
  }, []);

  useEffect(() => {
    localStorage.setItem("step", JSON.stringify(step));
    localStorage.setItem("formState", JSON.stringify(formState));
    localStorage.setItem("formData", JSON.stringify(formData));
    if (step === 1 && formData["Arte Pronta"]) {
      setFormData((prevData) => {
        // Remove "Arte Pronta" do formData
        const newFormData = { ...prevData };
        delete newFormData["Arte Pronta"];

        // Atualiza o localStorage com o formData modificado
        localStorage.setItem("formData", JSON.stringify(newFormData));

        return newFormData;
      });
    }
    if (step > totalSteps) {
      setStep(totalSteps-1);
    }
  }, [step, formState, formData]);
  useEffect(() => {
    // Rolando para o topo da página sempre que 'step' mudar
    window.scrollTo(0, 0);
  }, [step]);

  // Função para lidar com a seleção do arquivo
  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    // Defina os tipos de arquivo permitidos
    const allowedExtensions = ['.pdf', '.png', '.jpg', '.jpeg', '.ai', '.psd', '.cdr'];

    // Verifica se o arquivo existe e se tem uma extensão permitida
    if (file) {
        const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
            setUploading({ uploading: false, status: "Tipo de arquivo não permitido." });
            return; // Impede o upload se o tipo não for permitido
        }

        setUploading({ uploading: true, status: "Enviando arquivo..." });
        const response = await uploadFileToDropbox(file);
        
        if (response) {
            updateFormState("fileName", response.data.uploadResponse.name);
            setUploading({
                uploading: false,
                status: "Arquivo enviado com sucesso!",
            });
            setValue(`${event.target.name}`, response.data.sharingLink.url);
            setFormData((prevData) => ({
                ...prevData,
                [`${event.target.name}`]: response.data.sharingLink.url,
            }));
        } else {
            updateFormState("fileName", "");
            setValue(`${event.target.name}`, null);
            setUploading({ uploading: false, status: `Link expirado, favor clique em "teste nosso novo briefing" na sua área do cliente novamente` });
        }
    }
};
  const handleCheckboxChangeEstampa = (value) => {
    const newEstampa = formState.selectedEstampa === value ? null : value;

    // Atualiza o estado do formulário
    updateFormState("selectedEstampa", newEstampa);

    // Atualiza o estado local do formulário
    setFormData((prevState) => {
      const newFormData = { ...prevState }; // Cria uma cópia do estado anterior
      delete newFormData["Cor da Estampa"]; // Remove a chave 'Cor da Estampa'
      delete newFormData["Referência para estampa"]; // Remove a chave 'Referência para estampa'
      newFormData.Estampa = newEstampa; // Adiciona ou atualiza a chave 'Estampa' com o novo valor
      return newFormData; // Retorna o novo estado sem as chaves removidas
    });

    // Atualiza o valor do formulário
    setValue("Estampa", newEstampa);
  };

  const handleCheckboxChange = (value) => {
    updateFormState("logoPosition", value);
    setValue("Posição da Logo", value); // Ensure that the correct value is set
    setFormData((prevState) => ({
      ...prevState,
      ["Posição da Logo"]: value,
    }));
  };

  const handleCheckboxChangeNutrientsModel = (value) => {
    setFormState((prevState) => {
      const prevNutrientsModel = Array.isArray(prevState.nutrientsModel)
        ? prevState.nutrientsModel
        : [];
      const newNutrientsModel = prevNutrientsModel.includes(value)
        ? prevNutrientsModel.filter((item) => item !== value)
        : [...prevNutrientsModel, value];

      // Atualiza o valor do campo 'Modelo Nutrientes' no formulário
      setValue("Modelo Nutrientes", newNutrientsModel);
      setFormData((prevState) => ({
        ...prevState,
        ["Modelo Nutrientes"]: newNutrientsModel,
      }));

      return {
        ...prevState,
        nutrientsModel: newNutrientsModel,
      };
    });
  };

  const onNextStep = (skipSteps = 1) => {
    setUploading({ uploading: false, status: "" });
    if (formState.fileName) {
      updateFormState("fileName", null);
    }
    setStep((prevStep) => {
      const nextStep = prevStep + skipSteps;
      return nextStep > 16 ? 16 : nextStep;
    });
    localStorage.setItem("step", JSON.stringify(step));
  };

  const onPreviousStep = (skipSteps = 1) => {
    setStep((prevStep) => prevStep - skipSteps);
  };

  const onSubmit = (data) => {
    console.log(data);
    onNextStep();
  };

  if (briefingEnviado) {
    return (
      <div
        style={{
          background: "linear-gradient(to top left, white, #83a802)",
          minHeight: "100vh",
          maxWidth: "100vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontFamily: "sans-serif",
          padding: "10px",
        }}
      >
        <Header/>
        <BriefingEnviado />
      </div>
    );
  }

  if (!hasBriefing) {
    return (
      <div
        style={{
          background: "linear-gradient(to top left, white, #83a802)",
          minHeight: "100vh",
          maxWidth: "100vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontFamily: "Montserrat",
          padding: "10px",
        }}
      >
        <Header/>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <h1 style={{ textAlign: "center" }}>
            {fraseErro}
          </h1>
        </Box>
      </div>
    );
  }

  if (briefingForReplicant === null) {
    return (
      <div
      style={{
        background: "linear-gradient(to top left, white, #83a802)",
        minHeight: "100vh",
        maxWidth: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "Montserrat",
        padding: "10px",
      }}
      >
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
        >
          <Header/>
          <p>Carregando.... <CircularProgress size={20} /></p>
        </Box>
    </div>
    )
  }

  return (
    <div
      style={{
        background: "linear-gradient(to top left, white, #83a802)",
        minHeight: "100vh",
        maxWidth: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "Montserrat",
        padding: "10px",
      }}
    >
      <Header/>
      <h2 style={{ textAlign: "center" }}>
        Briefing do produto {infoOrder.product_name} - Pedido #
        {infoOrder.ns_order_number}
      </h2>
      <LinearWithValueLabel step={step} totalSteps={totalSteps} />
      <form onSubmit={handleSubmit(onSubmit)}>
        {step === 1 && (
          <GenericStepQuestion
            hasItem={formState.hasArt}
            setHasItem={(value) => updateFormState("hasArt", value)}
            onNextStep={onNextStep}
            question="Deseja replicar sua arte do pedido anterior?"
          />
        )}

        {step === 2 && (
          <Step2
            hasArt={formState.hasArt}
            handleFileChange={handleFileChange}
            onNextStep={onNextStep}
            fileName={formState.fileName}
            uploading={uploading}
            onPreviousStep={onPreviousStep}
            hasLogo={formState.hasLogo}
            setHasLogo={(value) => updateFormState("hasLogo", value)}
            briefingFinalizado={formState.briefingFinalizado}
            finalSteps={13}
            setFormData={setFormData}
            setHasArt={(value) => updateFormState("hasArt", value)}
            replicableArts={briefingForReplicant}
          />
        )}

        {step === 3 && (
          <Step3
            hasManual={formState.hasManual}
            handleFileChange={handleFileChange}
            onNextStep={onNextStep}
            fileName={formState.fileName}
            uploading={uploading}
            onPreviousStep={onPreviousStep}
            briefingFinalizado={formState.briefingFinalizado}
            register={register}
            hasLogo={formState.hasLogo}
            setHasLogo={(value) => updateFormState("hasLogo", value)}
            setFormData={setFormData}
            logo={formData.Logo}
            finalSteps={11}
          />
        )}

        {step === 4 && (
          <GenericStepQuestion
            hasItem={formState.hasManual}
            setHasItem={(value) => updateFormState("hasManual", value)}
            onNextStep={onNextStep}
            question="Gostaria de enviar seu manual de marca?"
            onPreviousStep={onPreviousStep}
          />
        )}

        {step === 5 && formState.hasManual === true && (
          <GenericUploadQuestion
            handleFileChange={handleFileChange}
            fileName={formState.fileName}
            uploading={uploading}
            onPreviousStep={onPreviousStep}
            onNextStep={onNextStep}
            question="Envie seu manual de marca aqui"
            gabarito={false}
            fileQuestionButton="Envie seu manual de marca aqui"
            briefingFinalizado={formState.briefingFinalizado}
            nameInput="Manual de Marca"
            steps={0}
            previousSteps={1}
            setHasManual={(value) => updateFormState("hasManual", value)}
            hasManual={formState.hasManual}
            finalSteps={9}
          />
        )}

        {step === 5 &&
          (formState.hasManual === false || formState.hasManual === null) && (
            <GenericStepQuestion
              hasItem={formState.hasEstampa}
              setHasItem={(value) => updateFormState("hasEstampa", value)}
              onNextStep={onNextStep}
              question="Você gostaria da sua embalagem com alguma estampa?"
              onPreviousStep={onPreviousStep}
              firstOption="Sim, quero enviar a referência."
              secondOption="Não, quero apenas a logo."
              steps={1}
            />
          )}
        {step === 6 && formState.hasEstampa === true && (
          <Box
            display="flex"
            id="box-estampa-referencia"
            flexDirection="column"
            alignSelf="center"
            justifyContent="center"
            alignItems="center"
          >
            <h1 style={{ textAlign: "center" }}>
              Envie a referência da sua estampa aqui
            </h1>
            <Box display="flex" justifyContent="center" margin="10px" />
            <textarea
              placeholder="Digite aqui como você gostaria que fosse sua estampa, se quiser mais de uma cor utilize o campo abaixo."
              style={{
                width: "90%",
                height: "100px",
                backgroundColor: "transparent",
                border: "1px solid green",
                borderRadius: "5px",
                fontSize: "16px",
                padding: "10px",
                fontFamily: "sans-serif",
              }}
              value={formData["Referência para estampa"] || ""}
              {...register("Referência para estampa", {
                required: false,
                validate: (value) =>
                  value.trim() !== "" ||
                  "Referência para estampa não pode estar vazia",
              })}
              onChange={(event) => {
                setValue("Referência para estampa", event.target.value);
                setFormData((prevData) => ({
                  ...prevData,
                  "Referência para estampa": event.target.value,
                }));
              }}
            />
            <ColorPicker
              register={register}
              setValue={setValue}
              setFormData={setFormData}
              formField={"Cor da Estampa"}
              formData={formData}
            />
            <Box display="flex" justifyContent="center" margin="10px" />
            <GenericUploadQuestion
              steps={1}
              handleFileChange={handleFileChange}
              fileName={formState.fileName}
              uploading={uploading}
              onPreviousStep={onPreviousStep}
              onNextStep={onNextStep}
              question="Envie sua referência aqui"
              gabarito={false}
              fileQuestionButton="Envie sua referência aqui"
              briefingFinalizado={formState.briefingFinalizado}
              nameInput="Estampa"
              register={register}
              setEstampaEnviada={(value) =>
                updateFormState("estampaEnviada", value)
              }
              estampaEnviada={formState.estampaEnviada}
              finalSteps={7}
            />
          </Box>
        )}
        {step === 6 && formState.hasEstampa === false && (
          <Box textAlign="center">
              <ColorPicker
              register={register}
              setValue={setValue}
              setFormData={setFormData}
              formField={"Cor da Estampa"}
              formData={formData}
              />
            <Box display="flex" justifyContent="center" margin="10px" />
            <Button
              variant="contained"
              type="button"
              onClick={() => onNextStep()}
              color="success"
            >
              Próximo
            </Button>
            <Box display="flex" justifyContent="center" margin="10px"></Box>
            <Button
              variant="contained"
              type="button"
              onClick={() => onPreviousStep()}
              color="secondary"
            >
              Anterior
            </Button>
          </Box>
        )}
        {step === 7 && (
          <GenericStepQuestion
            hasItem={formState.hasContactInfo}
            setHasItem={(value) => updateFormState("hasContactInfo", value)}
            onNextStep={onNextStep}
            question="Gostaria de adicionar informações de contato e redes sociais na sua embalagem?"
            onPreviousStep={onPreviousStep}
            nextSteps={formState.hasContactInfo === true ? 1 : 2}
          />
        )}
        {step === 8 && (
          <Box
            display="flex"
            flexDirection="column"
            alignContent="space-around"
            alignItems="center"
          >
            <h1 style={{ textAlign: "center" }}>
              Informe as informações de contato e redes sociais que deseja
              adicionar:
            </h1>
            <TextField
              label="Telefone"
              color="info"
              {...register("Telefone", { required: true })}
              value={formState.contactInfo.Telefone}
              onChange={(e) => {
                setFormState((prev) => ({
                  ...prev,
                  contactInfo: {
                    ...prev.contactInfo,
                    Telefone: e.target.value,
                  },
                }));
                setFormData((prevData) => ({
                  ...prevData,
                  Telefone: e.target.value,
                }));
              }}
            />
            <Box display="flex" justifyContent="center" margin="10px"></Box>
            <TextField
              label="Whatsapp"
              color="info"
              {...register("Whatsapp", { required: true })}
              value={formState.contactInfo.Whatsapp}
              onChange={(e) => {
                setFormState((prev) => ({
                  ...prev,
                  contactInfo: {
                    ...prev.contactInfo,
                    Whatsapp: e.target.value,
                  },
                }));

                setFormData((prevData) => ({
                  ...prevData,
                  WhatsApp: e.target.value,
                }));
              }}
            />
            <Box display="flex" justifyContent="center" margin="10px"></Box>
            <TextField
              label="Instagram"
              color="info"
              value={formState.contactInfo.Instagram}
              {...register("Instagram", { required: true })}
              onChange={(e) => {
                setFormState((prev) => ({
                  ...prev,
                  contactInfo: {
                    ...prev.contactInfo,
                    Instagram: e.target.value,
                  },
                }));
                setFormData((prevData) => ({
                  ...prevData,
                  Instagram: e.target.value,
                }));
              }}
            />
            <Box display="flex" justifyContent="center" margin="10px"></Box>
            <TextField
              label="Facebook"
              color="info"
              {...register("Facebook", { required: true })}
              value={formState.contactInfo.Facebook}
              onChange={(e) => {
                setFormState((prev) => ({
                  ...prev,
                  contactInfo: {
                    ...prev.contactInfo,
                    Facebook: e.target.value,
                  },
                }));
                setFormData((prevData) => ({
                  ...prevData,
                  Facebook: e.target.value,
                }));
              }}
            />
            <Box display="flex" justifyContent="center" margin="10px"></Box>
            <Button
              variant="contained"
              type="button"
              onClick={() => onNextStep()}
              color="success"
            >
              Próximo
            </Button>
            <Box display="flex" justifyContent="center" margin="10px"></Box>
            <Button
              variant="contained"
              type="button"
              onClick={() => onPreviousStep()}
              color="secondary"
            >
              Anterior
            </Button>
          </Box>
        )}
        {step === 9 && (
          <GenericStepQuestion
            hasItem={formState.hasNutritionalTable}
            setHasItem={(value) =>
              updateFormState("hasNutritionalTable", value)
            }
            onNextStep={onNextStep}
            question="Deseja incluir Tabela Nutricional?"
            onPreviousStep={onPreviousStep}
            steps={2}
            nextSteps={formState.hasNutritionalTable === true ? 1 : 2}
          />
        )}
        {step === 10 && (
          <GenericUploadQuestion
            handleFileChange={handleFileChange}
            fileName={formState.fileName}
            uploading={uploading}
            onPreviousStep={onPreviousStep}
            onNextStep={onNextStep}
            question="Envie a tabela nutricional aqui"
            gabarito={false}
            fileQuestionButton="Envie a tabela nutricional aqui"
            briefingFinalizado={formState.briefingFinalizado}
            nameInput="Tabela Nutricional"
            steps={1}
            setHasNutritionalTable={(value) =>
              updateFormState("hasNutritionalTable", value)
            }
            hasNutritionalTable={formState.hasNutritionalTable}
            finalSteps={3}
          />
        )}
        {step === 11 && (
          <Box
            display="flex"
            flexDirection="column"
            alignContent="center"
            alignItems="center"
          >
            <h1 style={{ textAlign: "center" }}>
              Deseja incluir modelo com alto teor de nutrientes?
            </h1>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formState.hasHighNutrientsModel === true}
                  onChange={() =>
                    updateFormState("hasHighNutrientsModel", true)
                  }
                />
              }
              label="Sim"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formState.hasHighNutrientsModel === false}
                  onChange={() =>
                    updateFormState("hasHighNutrientsModel", false)
                  }
                />
              }
              label="Não"
            />
            <Button
              color="success"
              variant="contained"
              type="button"
              onClick={() => {
                onNextStep(formState.hasHighNutrientsModel === true ? 1 : 2);
              }}
              disabled={formState.hasHighNutrientsModel === null}
            >
              Próximo
            </Button>
            <Box display="flex" justifyContent="center" margin="10px"></Box>
            <Button
              variant="contained"
              type="button"
              onClick={() => onPreviousStep(2)}
              color="secondary"
            >
              Anterior
            </Button>
          </Box>
        )}
        {step === 12 && (
          <Box
            display="flex"
            flexDirection="column"
            alignContent="center"
            alignItems="center"
          >
            <h1 style={{ textAlign: "center" }}>
              Selecione as opções desejadas
            </h1>
            <FormControlLabel
              control={
                <Checkbox
                  value="Alto em açúcar adicionado"
                  name="Alto em açúcar adicionado"
                  checked={formState.nutrientsModel.includes(
                    "Alto em açúcar adicionado"
                  )}
                  onChange={() =>
                    handleCheckboxChangeNutrientsModel(
                      "Alto em açúcar adicionado"
                    )
                  }
                />
              }
              label={
                <Box display="flex" alignItems="center">
                  <img
                    src={imageAltoEmAcucar}
                    alt="Alto em gordura"
                    style={{ marginLeft: 8, width: 150, height: 35 }}
                  />
                </Box>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  value="Alto em gordura saturada"
                  name="Alto em gordura saturada"
                  checked={formState.nutrientsModel.includes(
                    "Alto em gordura saturada"
                  )}
                  onChange={() =>
                    handleCheckboxChangeNutrientsModel(
                      "Alto em gordura saturada"
                    )
                  }
                />
              }
              label={
                <Box display="flex" alignItems="center">
                  <img
                    src={imageAltoEmGorduras}
                    alt="Alto em gordura"
                    style={{ marginLeft: 8, width: 150, height: 35 }}
                  />
                </Box>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  value="Alto em sódio"
                  name="Alto em sódio"
                  checked={formState.nutrientsModel.includes("Alto em sódio")}
                  onChange={() =>
                    handleCheckboxChangeNutrientsModel("Alto em sódio")
                  }
                />
              }
              label={
                <Box display="flex" alignItems="center">
                  <img
                    src={imageAltoEmSodio}
                    alt="Alto em gordura"
                    style={{ marginLeft: 8, width: 150, height: 35 }}
                  />
                </Box>
              }
            />
            <Box display="flex" justifyContent="center" margin="10px"></Box>
            <Button
              color="success"
              variant="contained"
              type="button"
              onClick={() => onNextStep()}
              disabled={formState.nutrientsModel.length === 0}
            >
              Próximo
            </Button>
            <Box display="flex" justifyContent="center" margin="10px"></Box>
            <Button
              variant="contained"
              type="button"
              onClick={() => onPreviousStep(1)}
              color="secondary"
            >
              Anterior
            </Button>
            {formState.briefingFinalizado && (
              <Box justifyContent="center" marginTop="10px">
                <Box justifyContent="center" margin="10px" />
                <Button
                  variant="contained"
                  color="info"
                  onClick={() => onNextStep(1)}
                >
                  Finalizar
                </Button>
              </Box>
            )}
          </Box>
        )}
        {step === 13 && (
          <GenericStepQuestion finalSteps={15} question={"Deseja anexar mais alguma referência ou deixar alguma observação?"} hasItem={formState.hasObservations} setHasItem={(value) => updateFormState("hasObservations", value)} onNextStep={onNextStep} onPreviousStep={onPreviousStep} nextSteps={formState.hasObservations ? 1 : 2} />
        )}
        {step === 14 && (
          <Box textAlign="center">
            <Box marginTop={3}/>
            <textarea
              placeholder="Deixe aqui sua observação. Ex: Gostaria de uma embalagem mais colorida, com mais informações sobre o produto, etc."
              style={{
                width: "90%",
                height: "100px",
                backgroundColor: "transparent",
                border: "1px solid green",
                borderRadius: "5px",
                fontSize: "16px",
                padding: "10px",
                textAlign: "center",
                fontFamily: "sans-serif",
              }}
              value={formData["Observações"] || ""}
              {...register("Observações", {
                required: false,
                validate: (value) =>
                  value.trim() !== "" ||
                  "Observações não pode estar vazia",
              })}
              onChange={(event) => {
                setValue("Observações", event.target.value);
                setFormData((prevData) => ({
                  ...prevData,
                  "Observações": event.target.value,
                }));
              }}
            />
            <Box height="20px" />
            <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
                <Input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="contained-button-file"
                    multiple
                    name="Mais Referências"
                    type="file"
                    onChange={handleFileChange}
                />
                <label htmlFor="contained-button-file">
                    <Button
                        variant="outlined"
                        component="span"
                        color="success"
                        startIcon={<FileUploadOutlined />}
                    >
                        Envie aqui o arquivo
                    </Button>
                </label>

                {formState.fileName && (
                    <Typography variant="body1" mt={2}>
                        Arquivo selecionado: {formState.fileName}
                    </Typography>
                )}

                {uploading.status && (
                    <Typography variant="body1" mt={2}>
                        {uploading.status} {uploading.uploading && <CircularProgress size={20} />}
                    </Typography>
                )}
                <p style={{ color: "#871111", textAlign: "center" }}><span>*</span>Aceitamos arquivos nas extensões Illustrator, Photoshop, Corel Draw, PDF ou PNG de alta resolução (mínimo 300dpi)</p>
                <Button
                  color="success"
                  variant="contained"
                  type="button"
                  onClick={() => onNextStep()}
                  disabled={formState.fileName === "" || formState.fileName === null}
                >
                  Próximo
                </Button>
                <Box display="flex" justifyContent="center" margin="10px"></Box>
                <Button
                  variant="contained"
                  type="button"
                  onClick={() => onPreviousStep()}
                  color="secondary"
                >
                  Anterior
                </Button>
            
            </Box>
        </Box>
        )}
        {step === 15 && (
          <Box
            display="flex"
            flexDirection="column"
            alignContent="center"
            alignItems="center"
          >
            <h1 style={{ textAlign: "center" }}>
              Revise as informações no próximo passo
            </h1>
            {/* <Button
              variant="contained"
              type="button"
              onClick={() => onPreviousStep(2)}
              color="secondary"
            >
              Anterior
            </Button> */}
            <Box display="flex" justifyContent="center" margin="10px" />
            <Button variant="contained" type="submit" color="success">
              Revisar
            </Button>
          </Box>
        )}
      </form>
      {step === 16 && (
        <Box display="flex" flexDirection="column">
          <h1 style={{ textAlign: "center" }}>Revise as informações</h1>
          {Object.keys(formData).map((key) => (
            <Box key={key} display="flex" mb={2} flexDirection="column">
              <p>
                <span style={{ fontWeight: "bold" }}>{key}</span>:
                {key === "Estampa" ||
                key === "Cor da Estampa" ||
                key === "Logo" ? (
                  formData[key].includes("http") ? (
                    <Box>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        margin="5px"
                      />
                      <Link
                        href={formData[key]}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Clique aqui para visualizar
                      </Link>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginTop="10px"
                      />
                      {/* <Button
                        variant="contained"
                        color="warning"
                        onClick={() => {
                          let stepValue = 1; // Default step
                          if (key === "Logo") stepValue = 13;
                          else if (key === "Manual de Marca") stepValue = 11;
                          else if (
                            key === "Estampa" ||
                            key === "Cor da Estampa"
                          )
                            stepValue = 9;
                          else if (key === "Tabela Nutricional") stepValue = 4;
                          updateFormState("briefingFinalizado", true);
                          onPreviousStep(stepValue);
                        }}
                      >
                        Editar
                      </Button> */}
                    <EditModal
                      question={key}
                      type={
                        key === "Cor da Estampa"
                          ? "colorPicker"
                          : key === "Estampa" ||
                            key === "Logo" ||
                            key === "Manual de Marca" ||
                            key === "Mais Referências" ||
                            key === "Tabela Nutricional"
                          ? "input"
                          : key === "Modelo Nutrientes" ?
                            "nutrientsModel"
                          : "text"
                      }
                      uploading={uploading}
                      fileQuestionButton={key}
                      handleFileChange={handleFileChange}
                      fileName={formState.fileName}
                      setFormData={setFormData}
                      nameInput={key}
                      formState={formState}
                      updateFormState={updateFormState}
                      setUploading={setUploading}
                      handleCheckboxChangeNutrientsModel={handleCheckboxChangeNutrientsModel}
                      setStep={setStep}
                    />

                    </Box>
                  ) : (
                    <Box>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        margin="5px"
                      />
                      {formData[key]}
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginTop="10px"
                      />
                      {/* <Button
                        variant="contained"
                        color="warning"
                        onClick={() => {
                          let stepValue = 1; // Default step
                          if (key === "Logo") stepValue = 15;
                          else if (key === "Manual de Marca") stepValue = 11;
                          else if (
                            key === "Estampa" ||
                            key === "Cor da Estampa"
                          )
                            stepValue = 9;
                          else if (key === "Tabela Nutricional") stepValue = 4;
                          updateFormState("briefingFinalizado", true);
                          onPreviousStep(stepValue);
                        }}
                      >
                        Editar
                      </Button> */}
                    <EditModal
                      question={key}
                      type={
                        key === "Cor da Estampa"
                          ? "colorPicker"
                          : key === "Estampa" ||
                            key === "Logo" ||
                            key === "Manual de Marca" ||
                            key === "Mais Referências" ||
                            key === "Tabela Nutricional"
                          ? "input"
                          : key === "Modelo Nutrientes" ?
                            "nutrientsModel"
                          : "text"
                      }
                      uploading={uploading}
                      fileQuestionButton={key}
                      handleFileChange={handleFileChange}
                      fileName={formState.fileName}
                      setFormData={setFormData}
                      nameInput={key}
                      formState={formState}
                      updateFormState={updateFormState}
                      setUploading={setUploading}
                      handleCheckboxChangeNutrientsModel={handleCheckboxChangeNutrientsModel}
                      setStep={setStep}
                    />
                    </Box>
                  )
                ) : key === "Modelo Nutrientes" ? (
                  <Box>
                    {formData[key].map((item, index) => (
                      <Box
                        key={index}
                        display="flex"
                        justifyContent="flex-start"
                        margin="5px"
                      >
                        <List>
                          <ListItem>* {item}</ListItem>
                        </List>
                      </Box>
                    ))}
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      marginTop="10px"
                    >
                    <EditModal
                      question={key}
                      type={
                        key === "Cor da Estampa"
                          ? "colorPicker"
                          : key === "Estampa" ||
                            key === "Logo" ||
                            key === "Manual de Marca" ||
                            key === "Mais Referências" ||
                            key === "Tabela Nutricional"
                          ? "input"
                          : key === "Modelo Nutrientes" ?
                            "nutrientsModel"
                          : "text"
                      }
                      uploading={uploading}
                      fileQuestionButton={key}
                      handleFileChange={handleFileChange}
                      fileName={formState.fileName}
                      setFormData={setFormData}
                      nameInput={key}
                      formState={formState}
                      updateFormState={updateFormState}
                      setUploading={setUploading}
                      handleCheckboxChangeNutrientsModel={handleCheckboxChangeNutrientsModel}
                      setStep={setStep}
                    />
                    </Box>
                  </Box>
                ) :
                  key === "Mais Referências" ||
                  key === "Manual de Marca" ||
                  key === "Estampa" ||
                  key === "Tabela Nutricional" ||
                  key === "Logo" ||
                  key === "Referência" ||
                  key === "Cor da Estampa" ? (
                  <Box>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      margin="5px"
                    />
                    <Link href={formData[key]} target="_blank" rel="noreferrer">
                      Clique aqui para visualizar
                    </Link>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      marginTop="10px"
                    >
                      {/* <Button
                        variant="contained"
                        color="warning"
                        onClick={() => {
                          let stepValue = 1; // Default step
                          if (key === "Logo") stepValue = 13;
                          else if (key === "Manual de Marca") stepValue = 11;
                          else if (
                            key === "Estampa" ||
                            key === "Cor da Estampa"
                          )
                            stepValue = 9;
                          else if (key === "Tabela Nutricional") stepValue = 4;
                          else if ((key === "Arte Pronta" || key === "Referência")) stepValue = 15;
                          else if (key === "Mais Referências") stepValue = 2;
                          updateFormState("briefingFinalizado", true);
                          onPreviousStep(stepValue);
                        }}
                      >
                        Editar
                      </Button> */}
                      <EditModal
                        question={key}
                        type={
                          key === "Cor da Estampa"
                            ? "colorPicker"
                            : key === "Estampa" ||
                              key === "Logo" ||
                              key === "Manual de Marca" ||
                              key === "Mais Referências" ||
                              key === "Tabela Nutricional"
                            ? "input"
                            : key === "Modelo Nutrientes" ?
                              "nutrientsModel"
                            : "text"
                        }
                        uploading={uploading}
                        fileQuestionButton={key}
                        handleFileChange={handleFileChange}
                        fileName={formState.fileName}
                        setFormData={setFormData}
                        nameInput={key}
                        formState={formState}
                        updateFormState={updateFormState}
                        setUploading={setUploading}
                        handleCheckboxChangeNutrientsModel={handleCheckboxChangeNutrientsModel}
                        setStep={setStep}
                      />
                    </Box>
                  </Box>
                ) : key === "Arte Pronta" ? (
                  <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-start"
                      margin="5px"
                    >
                    <Link href={formData[key]} target="_blank" rel="noreferrer">
                      Clique aqui para visualizar
                    </Link>
                    <Box display="flex"
                      justifyContent="flex-start"
                      marginTop="10px">
                    <Button
                        variant="contained"
                        color="warning"
                        onClick={() => {
                          let stepValue = 1; // Default step
                          if (key === "Logo") stepValue = 13;
                          else if (key === "Manual de Marca") stepValue = 11;
                          else if (
                            key === "Estampa" ||
                            key === "Cor da Estampa"
                          )
                            stepValue = 9;
                          else if (key === "Tabela Nutricional") stepValue = 4;
                          else if ((key === "Arte Pronta" || key === "Referência")) stepValue = 15;
                          else if (key === "Mais Referências") stepValue = 2;
                          updateFormState("briefingFinalizado", true);
                          onPreviousStep(stepValue);
                        }}
                      >
                        Editar
                      </Button>
                      </Box>
                  </Box>
                ) : (
                  <TextField
                    variant="standard"
                    sx={{ width: "100%", height: "40px" }}
                    value={formData[key]}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        [key]: e.target.value,
                      }))
                    }
                  />
                )}
              </p>
            </Box>
          ))}
          <Box mt={2} mb={2}>
            <BasicModal></BasicModal>
          </Box>
          <Box mt={2} mb={2}>
            <Termos
              setTermos={setTermos}
              termos={termos}
              confirmacaoReplicacao={confirmacaoReplicacao}
              setConfirmacaoReplicacao={setConfirmacaoReplicacao}
              showConfirmacaoReplicacao={confirmacaoReplicacao !== null ? true : false}
              />
          </Box>       
          <Box display="flex" alignItems="center" justifyContent="center">
            <Button
              variant="contained"
              type="button"
              onClick={() => onPreviousStep()}
              color="secondary"
            >
              Anterior
            </Button>
            <Box display="flex" justifyContent="center" margin="10px" />
            <Button
              variant="contained"
              type="submit"
              color="success"
              disabled={!termos || (confirmacaoReplicacao !== null && !confirmacaoReplicacao)}
              onClick={async () => {
                const cliente = new URLSearchParams(window.location.search).get(
                  "cliente"
                );
                const pedido = new URLSearchParams(window.location.search).get(
                  "numero_pedido"
                );
                const produto = new URLSearchParams(window.location.search).get(
                  "produto"
                );
                const briefing = await saveBriefing(
                  formData,
                  pedido,
                  cliente,
                  produto,
                  uploading,
                  setUploading,
                  setBriefingEnviado,
                  infoOrder
                );
                console.log("Briefing no botão" + briefing);
              }}
            >
              Enviar
            </Button>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            margin="10px"
            alignItems="center"
          />
          <Box textAlign="center">
            {uploading.status && (
              <Typography variant="body1" mt={2}>
                {uploading.status}{" "}
                {uploading.uploading && <CircularProgress size={20} />}
              </Typography>
            )}
          </Box>
        </Box>
      )}
      <Footer/>
    </div>
  );
}

export default BriefingTampa;
