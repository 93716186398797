import React, { useEffect, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { TextureLoader, CanvasTexture, RepeatWrapping } from 'three';
import { useLoader } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { Box } from '@mui/material';

const Cup3D = ({ logo, estampa }) => {
  const [combinedTexture, setCombinedTexture] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadTextures = async () => {
      const baseTexture = await new Promise((resolve) => {
        new TextureLoader().load(estampa, resolve);
      });
      const logoTexture = await new Promise((resolve) => {
        new TextureLoader().load(logo, resolve);
      });

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      // Define o tamanho do canvas para o tamanho da textura base
      canvas.width = baseTexture.image.width;
      canvas.height = baseTexture.image.height;

      // Desenha a textura base no canvas
      context.drawImage(baseTexture.image, 0, 0);

      // Tamanho da logo desejada
      const logoScale = 0.25; // Escala desejada (25% do tamanho da base)
      const logoScaleHeight = 0.50; // Escala desejada (25% do tamanho da base)
      const logoWidth = baseTexture.image.width * logoScale;
      const logoHeight = baseTexture.image.height * logoScaleHeight;


      const scaledLogoWidth = (logoWidth / canvas.width) * baseTexture.image.width;
      const scaledLogoHeight = (logoHeight / canvas.height) * baseTexture.image.height;

      const logoX = (canvas.width - scaledLogoWidth) / 2; // Centraliza horizontalmente
      const logoY = (canvas.height - scaledLogoHeight) / 2; // Centraliza verticalmente

      context.drawImage(logoTexture.image, logoX, logoY, scaledLogoWidth, scaledLogoHeight);

      const combined = new CanvasTexture(canvas);
      combined.wrapS = RepeatWrapping;
      combined.wrapT = RepeatWrapping;

      setCombinedTexture(combined);
      setLoading(false);
    };

    loadTextures();
  }, [estampa, logo]);

  if (loading) {
    return <p>Carregando...</p>;
  }

  return (
    <Box width="70vh" height="70vh"> {/* Ajuste a altura conforme necessário */}
      <Canvas>
        <OrbitControls />
        <ambientLight intensity={3} /> {/* Ajuste conforme necessário */}
        <pointLight position={[10, 10, 10]} />
        <mesh>
          <cylinderGeometry args={[1, 0.7, 3, 32]} />
          <meshStandardMaterial map={combinedTexture} side={2} />
        </mesh>

        <mesh position={[0, 1.5, 0]}>
          <cylinderGeometry args={[1.05, 1.049, 0.07, 32]} />
          <meshStandardMaterial color="white" />
        </mesh>

        <mesh position={[0, -1.51, 0]} rotation-x={Math.PI / 2}>
          <circleGeometry args={[0.7, 32]} />
          <meshStandardMaterial color="white" />
        </mesh>
      </Canvas>
    </Box>
  );
};

export default Cup3D;
