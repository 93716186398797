import { Box, Checkbox, FormControlLabel, Link, Typography } from "@mui/material";

const Termos = ({setTermos, termos, setConfirmacaoReplicacao, confirmacaoReplicacao, showConfirmacaoReplicacao}) => {
    return (
        <Box textAlign="center" display="flex" flexDirection="column" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  value="Eu concordo com os termos de uso."
                  name="Eu concordo com os termos de uso."
                  checked={termos}
                  onChange={(e) => setTermos(e.target.checked)}
                />
              }
              label={
                <>
                  <Box display="flex" alignItems="center" justifyContent="center" textAlign="center">
                      <Typography variant="body1" fontFamily="Montserrat">Eu concordo com os termos de uso. <Link  href="https://papello.com.br/termos-de-uso" target="_blank" fontFamily="Montserrat">*Ler termos de uso </Link></Typography>
                  </Box>
                  
                </>
              }
            />
            {showConfirmacaoReplicacao && (
            <FormControlLabel
              control={
                <Checkbox
                  value="Eu concordo em replicar minha arte do pedido anterior."
                  name="Eu concordo em replicar minha arte do pedido anterior."
                  checked={confirmacaoReplicacao}
                  onChange={(e) => setConfirmacaoReplicacao(e.target.checked)}
                />
              }
              label={
                <>
                  <Box display="flex" alignItems="center" justifyContent="center" textAlign="center">
                      <Typography variant="body1" fontFamily="Montserrat">Eu concordo em replicar minha arte do pedido anterior <span style={{color:"red", fontWeight:"bold"}}>SEM NENHUM TIPO DE ALTERAÇÃO.</span></Typography>
                  </Box>
                </>
              }
            />
            )}
          </Box>
    )

}

export default Termos;