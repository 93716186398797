import { Box, Button, Card, CardActionArea, CardContent, CardMedia, Checkbox, CircularProgress, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import React from "react";
import {imagensDoceria}  from "../img/DOCERIA/estampasDoceria";
import { imagensDrink } from "../img/DRINKS/drinksEstampas";
import { imagensPadaria } from "../img/PADARIA/estampasPadaria";
import { imagensRestauranteDelivery } from "../img/RESTAURANTE - DELIVERY - LANCHONETE/estampasRestauranteDelivery";
import { imagensMilkShake } from "../img/MILK SHAKE/estampasMilkShake";
import { imagensClinica } from "../img/CLÍNICA/estampasClinica";
import { imagensCafeteria } from "../img/CAFETERIA/estampasCafeteria";
import { imagensEscritorio } from "../img/ESCRITÓRIO/estampasEscritorio";
import { imagensAcai } from "../img/AÇAÍ/estampasAcai";
import { imagensSorveteria } from "../img/SORVETERIA/estampasSorveteria";
import { imagensNATAL } from "../img/NATAL/estampasNatal";
import { useState } from "react";

const Step6 = ({
    onNextStep, onPreviousStep, register, setSegmento, segmento, selectedEstampa, handleCheckboxChangeEstampa, uploading, briefingFinalizado, finalSteps, setFormData, setStep, totalSteps
 }) => {
  
  const [outroSegmento, setOutroSegmento] = useState(null);
  const segmentos = {
    "Doceria": {
      "estampas": imagensDoceria 
    },
    "Drinks": {
      "estampas": imagensDrink 
    },
    "Padaria": {
      "estampas": imagensPadaria 
    },
    "Restaurante/Delivery": {
      "estampas": imagensRestauranteDelivery
    },
    "Milk Shake": {
      "estampas": imagensMilkShake
    },
    "Clínica": {
      "estampas": imagensClinica
    },
    "Cafeteria": {
      "estampas": imagensCafeteria
    },
    "Escritório": {
      "estampas": imagensEscritorio
    },
    "Açai": {
      "estampas": imagensAcai
    },
    "Sorveteria": {
      "estampas": imagensSorveteria
    },
    "Natal": {
      "estampas": imagensNATAL
    },
    "Outros": {
      "estampas": []
    }
  };

    return (
    <Box display="flex" flexDirection="column" alignContent="center" alignItems="center">
    <Box>
      <h1 style={{textAlign:"center"}}>Selecione seu segmento:</h1>
      <Box>
      <FormControl fullWidth>
      <InputLabel id="segmento-label" >Selecione seu segmento aqui</InputLabel>
        <Select  {...register('Segmento', { required: true })} label="Segmento" onChange={(e) => {
          setFormData(prevState => ({ ...prevState, Segmento: e.target.value }));
          setSegmento(e.target.value)}} value={segmento}>
          <MenuItem  value="Doceria">Doceria</MenuItem >
          <MenuItem  value="Drinks">Drinks</MenuItem >
          <MenuItem  value="Restaurante/Delivery">Restaurante/Delivery</MenuItem >
          <MenuItem  value="Milk Shake">Milk Shake</MenuItem >
          <MenuItem  value="Padaria">Padaria</MenuItem >
          <MenuItem  value="Clínica">Clínica</MenuItem >
          <MenuItem  value="Cafeteria">Cafeteria</MenuItem >
          <MenuItem  value="Escritório">Escritório</MenuItem >
          <MenuItem  value="Açai">Açai</MenuItem >
          <MenuItem  value="Sorveteria">Sorveteria</MenuItem >
          <MenuItem  value="Natal">Natal</MenuItem >
          <MenuItem  value="Outros">Outros</MenuItem >
        </Select>
      </FormControl>
      </Box>
    </Box>
      <Box>
        {(segmento && segmento !== "Outros") && (
          <Box>
          <h1 style={{ textAlign: "center" }}>Selecione uma das estampas</h1>
          <Box
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',  // 1 coluna em telas pequenas
              sm: 'repeat(2, 1fr)',  // 2 colunas em telas pequenas/médias
              md: 'repeat(3, 1fr)'   // 3 colunas em telas médias/grandes
            }} // 3 colunas de largura igual
            gap="10px" // Espaçamento entre os itens
            width="100%"
            justifyContent="center"
            alignItems="center"
            justifyItems="center"
          >
            
            {((segmento && segmento !== "Outros") && segmentos[segmento].estampas.length > 0) ? segmentos[segmento].estampas.map((image, index) => (
              <Box>
              
              <Card
               key={index}   sx={{ 
                width: { 
                  xs: "100%", // 500px em dispositivos pequenos
                  sm: "100%", // 300px em dispositivos pequenos/médios
                  md: "100%"  // 200px em dispositivos médios/grandes
                }
          
              }}>
              <CardActionArea onClick={() => handleCheckboxChangeEstampa(`https://briefing.papellodev.site${image.caminho}`)}>
                <CardMedia
                  component="img"
                  height="250"
                  image={image.caminho}
                  alt="Estampa"
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    {image.nome}
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedEstampa === `https://briefing.papellodev.site${image.caminho}`}
                        onChange={() => handleCheckboxChangeEstampa(`https://briefing.papellodev.site${image.caminho}`)}
                        onClick={(e) => e.stopPropagation()} // Evita a propagação do clique no card ao clicar no checkbox
                      />
                    }
                    label="Selecionar"
                  />
                </CardContent>
              </CardActionArea>
              </Card>
              </Box>
            )) : <></>}
          </Box>
          </Box>
        )}

</Box>
      <Box display="flex" justifyContent="center" margin="10px"/>
     
     {((!selectedEstampa && segmento) && (segmento !== "Outros")) &&  (
      <Box display="flex" flexDirection="column" justifyContent="center" margin="10px" alignItems="center">
          <h1 style={{textAlign:"center"}}>Não gostou de nenhuma estampa?</h1>
          <Box display="flex" justifyContent="center" margin="10px"/>
          <Button variant="contained" type="button" onClick={() => {
              onNextStep(0.5)
          }
          } color="error">Não, quero enviar uma referência</Button>
      </Box>
      )}
          {segmento === "Outros" && (
      <Box alignItems="center">
        <TextField
          placeholder="Especifique o segmento"
          onChange={(e) => {
            setFormData(prevState => ({ ...prevState, Segmento: `Outros ${e.target.value}` }));
            setOutroSegmento(e.target.value)
            }
          }
          
        ></TextField> 
      </Box>
    )}
      { uploading.status && (<Typography variant="body1" mt={2}>{uploading.status} { uploading.uploading && (<CircularProgress/>)}</Typography>)}

      <Box display="flex" justifyContent="center" margin="10px"/>
      <Button disabled={selectedEstampa === null && outroSegmento === null}  variant="contained" type="button" onClick={() => onNextStep(outroSegmento? 0.5 : 1)} color="success">Próximo</Button>
      <Box display="flex" justifyContent="center" margin="10px"/>
      <Button variant="contained" type="button" onClick={() => onPreviousStep()} color="secondary">Anterior</Button>
      <Box display="flex" justifyContent="center" margin="10px"/>
      {briefingFinalizado && (         
      <Button variant="contained" color="info" onClick={() => setStep(totalSteps)}>Finalizar</Button>
      )}

  </Box>
)
}

export default Step6;